import { Navigate, Outlet } from "react-router-dom";
import PrivateLayout from "../Components/Layout/PrivateLayout";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../store/slices/authSlice";

function PrivateRoute() {
  const token = useSelector(selectCurrentToken);
  return token ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
