import { Input, Popconfirm, Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { deleteSchool, getSchools } from "../../api/schoolApi";
import { LoadingOutlined, EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import NoResult from "../../Components/UI/NoResult/NoResult";
import { NavLink } from "react-router-dom";
import "./style.scss";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { loading, schools } = useSelector((state) => state.schools);
  const [searchTerm, setSearchTerm] = useState("");
  const handleDeleteSchool = async (id) => {
    try {
      await dispatch(deleteSchool(id));
      dispatch(getSchools());
    } catch (error) {}
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchTerm],
      onFilter: (value, record) => record?.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "35%",
      responsive: ["lg"],
    },
    {
      title: "recommended",
      key: "recommended",
      dataIndex: "recommended",
      sorter: (a, b) => a.recommended - b.recommended,
      render: (_, { recommended }) => {
        const color = recommended ? "green" : "blue";
        return <Tag color={color}>{recommended ? "Recommended" : "In Review"}</Tag>;
      },
    },
    {
      title: "Actions",
      dataIndex: "_id",
      key: "_id",
      render: (_, record) => (
        <div className="table-action">
          <NavLink to={`/edit/${record._id}`}>
            <EditTwoTone style={{ fontSize: 22 }} />
          </NavLink>
          <Popconfirm
            title="Delete school"
            description="Are you sure to delete this school?"
            okText="Yes"
            cancelText="Cancel"
            align="left"
            onConfirm={() => handleDeleteSchool(record._id).then(() => console.log("123"))}
          >
            <DeleteTwoTone style={{ fontSize: 22 }} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getSchools());
  }, [dispatch]);

  useEffect(() => {
    if (schools) {
    }
  }, [schools]);

  return (
    <div className="container dashboard">
      {loading ? (
        <Spin
          className="my__spinner"
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 85,
              }}
            />
          }
        />
      ) : schools?.length > 0 ? (
        <div className="table__wrapper">
          <Input
            placeholder="Search school"
            onChange={(e) => setSearchTerm(e.target.value)}
            allowClear={true}
            style={{ marginBottom: "40px" }}
          />

          <Table dataSource={schools} columns={columns} pagination={null} />
        </div>
      ) : (
        <NoResult>
          <p>No School added</p>
          <NavLink className="nav__link" to="/create">
            Add new school
          </NavLink>
        </NoResult>
      )}
    </div>
  );
};

export default Dashboard;
