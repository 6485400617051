import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./hoc/PrivateRoute";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import EditSchool from "./Pages/EditSchool/EditSchool";
import AddSchool from "./Pages/AddSchool/AddSchool";
import "./App.scss";
import Statistic from "./Pages/Statistic/Statistic";

const App = () => {
  return (
    <div className="application">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/statistic" element={<Statistic />} />
          <Route path="/edit/:id" element={<EditSchool />} />
          <Route path="/add-school" element={<AddSchool />} />
          <Route path="*" element={<div>404</div>} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
