import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAreaStatistic, fetchBudgetStatistic, fetchCurriculumStatistic } from "../../api/statisticApi";
import { Table } from "antd";
import "./style.scss";

const AreaColumns = [
  {
    title: "Areas",
    dataIndex: "name",
    key: "name",
    width: "500px",
  },
  {
    title: "Users Searched",
    dataIndex: "count",
    key: "count",
  },
];
const BudgetColumns = [
  {
    title: "Budget Type",
    dataIndex: "displayType",
    key: "displayType",
    width: "500px",
  },
  {
    title: "Users Searched",
    dataIndex: "count",
    key: "count",
  },
];
const CurriculumColumns = [
  {
    title: "Curriculum Type",
    dataIndex: "name",
    key: "name",
    width: "500px",
  },
  {
    title: "Users Searched",
    dataIndex: "count",
    key: "count",
  },
];

const Statistic = () => {
  const {
    areaStatistic,
    areaStatisticLoading,
    budgetStatistic,
    budgetStatisticLoading,
    curriculumStatistic,
    curriculumStatisticLoading,
  } = useSelector((state) => state.statistic);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAreaStatistic());
    dispatch(fetchBudgetStatistic());
    dispatch(fetchCurriculumStatistic());
  }, [dispatch]);
  return (
    <div className="container">
      <div className="statistic__wrapper">
        <h4>Budget Statistic</h4>
        <Table
          dataSource={budgetStatistic}
          columns={BudgetColumns}
          pagination={false}
          loading={budgetStatisticLoading}
        />
      </div>

      <div className="statistic__wrapper">
        <h4>Area Statistic</h4>
        <Table
          dataSource={areaStatistic}
          columns={AreaColumns}
          pagination={false}
          loading={areaStatisticLoading}
        />
      </div>

      <div className="statistic__wrapper">
        <h4>Curriculum Statistic</h4>
        <Table
          dataSource={curriculumStatistic}
          columns={CurriculumColumns}
          pagination={false}
          loading={curriculumStatisticLoading}
        />
      </div>
    </div>
  );
};

export default Statistic;
