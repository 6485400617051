import React, { useEffect, useState } from "react";
import { Button, Input, Typography, Checkbox, Spin, Select, Divider } from "antd";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as MoveIcon } from "../../assets/move-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolByID, updateSchool } from "../../api/schoolApi";
import { useParams } from "react-router-dom";
import { LoadingOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import "./style.scss";
import NoResult from "../../Components/UI/NoResult/NoResult";
import { AREA_TAGS, CURRENCIES } from "../../utils/constants";
const { Title } = Typography;
const { Text } = Typography;

const EditSchool = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { school, loading } = useSelector((state) => state.schools);
  const [descriptions, setDescriptions] = useState([""]);
  const [areaTags, setAreaTags] = useState([]);

  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (index) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index) => {
    if (draggedItem === null || draggedItem === index) {
      return;
    }

    const updatedItems = [...descriptions];
    const draggedString = updatedItems[draggedItem];
    updatedItems.splice(draggedItem, 1);
    updatedItems.splice(index, 0, draggedString);

    setDescriptions(updatedItems);
    setDraggedItem(index);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleChangeDescription = (index, value) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = value;
    setDescriptions(updatedDescriptions);
  };

  const handleRemoveDescription = (index) => {
    setDescriptions(() => {
      return descriptions.filter((_, i) => i !== index);
    });
  };

  const addDescriptionFiled = () => {
    setDescriptions([...descriptions, ""]);
  };

  const handleAddSchool = (data) => {
    const updatedSchool = Object.assign(data, { description: descriptions }, { areaTag: areaTags });
    dispatch(updateSchool({ id, data: updatedSchool }));
  };

  useEffect(() => {
    dispatch(getSchoolByID(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (school) {
      setValue("name", school.name);
      setValue("curriculum", school?.curriculum);
      setValue("yearsOfStudy", school?.yearsOfStudy);
      setValue("area", school?.area);
      setValue("budget", school?.budget);
      setValue("recommended", school?.recommended);
      setValue("currency", school?.currency);
      setValue("nationality", school?.nationality);
      setValue("schoolUrl", school.schoolUrl);
      // setValue("areaTag", school?.areaTag);
      setDescriptions(school.description);
      setAreaTags(school?.areaTag);
    }
  }, [setValue, school]);

  if (!school && !loading) {
    return (
      <div className="container create-school">
        <NoResult>School not found </NoResult>
      </div>
    );
  }

  const handleChangeAreaTags = (value) => {
    setAreaTags(value);
  };

  return (
    <div className="container create-school">
      {loading ? (
        <Spin
          className="my__spinner"
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 85,
              }}
            />
          }
        />
      ) : (
        <>
          <Title className="title" level={2}>
            Edit - {school?.name}
          </Title>
          <form onSubmit={handleSubmit(handleAddSchool)}>
            <Controller
              control={control}
              name="name"
              rules={{
                required: "School name is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  status={errors.name ? "error" : ""}
                  className="default-input"
                  placeholder="Name"
                  size="large"
                  style={{ height: "55px" }}
                />
              )}
            />
            {errors.name && (
              <Text type="danger" className="error__validation">
                {errors.name.message}
              </Text>
            )}
            <Controller
              control={control}
              name="curriculum"
              rules={{
                required: "Curriculum of  school  is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  status={errors.curriculum ? "error" : ""}
                  className="default-input"
                  placeholder="Curriculum of  school"
                  size="large"
                  style={{ height: "55px" }}
                />
              )}
            />
            {errors.curriculum && (
              <Text type="danger" className="error__validation">
                {errors.curriculum.message}
              </Text>
            )}
            {/*  */}
            <Controller
              control={control}
              name="yearsOfStudy"
              rules={{
                required: "Years of study is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  status={errors.yearsOfStudy ? "error" : ""}
                  className="default-input"
                  placeholder="Year of study"
                  size="large"
                  style={{ height: "55px" }}
                />
              )}
            />
            {errors.yearsOfStudy && (
              <Text type="danger" className="error__validation">
                {errors.yearsOfStudy.message}
              </Text>
            )}
            {/*  */}
            <Controller
              control={control}
              name="area"
              rules={{
                required: "Address of school is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  status={errors.area ? "error" : ""}
                  className="default-input"
                  placeholder="Address of school"
                  size="large"
                  style={{ height: "55px" }}
                />
              )}
            />
            {errors.area && (
              <Text type="danger" className="error__validation">
                {errors.area.message}
              </Text>
            )}
            {/*  */}

            <Select
              size="large"
              mode="multiple"
              value={areaTags}
              placeholder="School Area"
              onChange={handleChangeAreaTags}
              options={AREA_TAGS}
            />
            {/* <Controller
              control={control}
              name="areaTag"
              rules={{
                required: "Area of School is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  status={errors.area ? "error" : ""}
                  className="default-input"
                  placeholder="School Area"
                  size="large"
                  style={{ height: "55px" }}
                />
              )}
            />
            {errors.areaTag && (
              <Text type="danger" className="error__validation">
                {errors.areaTag.message}
              </Text>
            )} */}

            {/*  */}
            <Controller
              control={control}
              name="currency"
              rules={{
                required: "Currency are required",
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  size="large"
                  value={value}
                  placeholder="Currency"
                  onChange={onChange}
                  options={CURRENCIES}
                  style={{ height: "55px" }}
                />
              )}
            />
            {errors.currency && (
              <Text type="danger" className="error__validation">
                {errors.currency.message}
              </Text>
            )}
            {/*  */}
            <Controller
              control={control}
              name="budget"
              rules={{
                required: "Budget of school is required",
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  type="number"
                  value={value}
                  onChange={onChange}
                  status={errors.budget ? "error" : ""}
                  className="default-input"
                  placeholder="Budget of school per year"
                  size="large"
                  style={{ height: "55px" }}
                />
              )}
            />
            {errors.budget && (
              <Text type="danger" className="error__validation">
                {errors.budget.message}
              </Text>
            )}
            {/*  */}
            <Controller
              control={control}
              name="nationality"
              rules={{
                required: "Nationality of schools are required",
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  status={errors.nationality ? "error" : ""}
                  className="default-input"
                  placeholder="Nationality"
                  size="large"
                  style={{ height: "55px" }}
                />
              )}
            />
            {errors.nationality && (
              <Text type="danger" className="error__validation">
                {errors.nationality.message}
              </Text>
            )}
            <Controller
              control={control}
              name="schoolUrl"
              render={({ field: { onChange, value } }) => (
                <Input
                  allowClear={true}
                  value={value}
                  onChange={(e) => {
                    const newValue = e.target.value.trim() === "" ? null : e.target.value;
                    onChange(newValue);
                  }}
                  status={errors.nationality ? "error" : ""}
                  className="default-input"
                  placeholder="School Website URL"
                  size="large"
                  style={{ height: "55px" }}
                />
              )}
            />
            <Divider dashed={true} />
            {descriptions.length > 0 &&
              descriptions.map((description, idx) => (
                <div
                  key={idx}
                  className="description__item"
                  onDragOver={() => handleDragOver(idx)}
                  onDragEnd={handleDragEnd}
                  style={{ backgroundColor: draggedItem === idx ? "#c3c3c3" : "transparent" }}
                >
                  <div
                    className="draggble__item"
                    draggable
                    onDragStart={() => handleDragStart(idx)}
                    style={{
                      cursor: "move",
                      userSelect: "none",
                    }}
                  >
                    <MoveIcon style={{ fill: draggedItem === idx ? "blue" : "#000000" }} />
                  </div>
                  <Input
                    value={description}
                    onChange={(e) => handleChangeDescription(idx, e.target.value)}
                    size="large"
                    className="description__input"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveDescription(idx)}
                    className="delete-description_btn"
                  >
                    <DeleteOutlined style={{ fontSize: "20px" }} />
                  </button>
                </div>
              ))}
            <div className="add-description__wrapper">
              <Button type="button" onClick={addDescriptionFiled} className="add-description__button">
                Add description field
                <PlusOutlined size="large" />
              </Button>
            </div>
            <div className="rate-container">
              <Controller
                control={control}
                name="recommended"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Checkbox onChange={onChange} checked={value}>
                    Parents Preferred School
                  </Checkbox>
                )}
              />
            </div>
            <Button type="primary" htmlType="submit" size="large" className="add-school__btn">
              Update
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default EditSchool;
