export const CURRENCIES = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "EGP",
    value: "EGP",
  },
];

export const AREA_TAGS = [
  {
    label: "Maadi",
    value: "Maadi",
  },
  {
    label: "Zahraa al Maadi",
    value: "Zahraa al Maadi",
  },
  {
    label: "New Cairo",
    value: "New Cairo",
  },
  {
    label: "West Cairo",
    value: "West Cairo",
  },
  {
    label: "Mokattam",
    value: "Mokattam",
  },
  {
    label: "Heliopolis",
    value: "Heliopolis",
  },
  {
    label: "Zamalek",
    value: "Zamalek",
  },
  {
    label: "New Capital",
    value: "New Capital",
  },
];
