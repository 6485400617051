import { createSlice } from "@reduxjs/toolkit";
import { fetchAreaStatistic, fetchBudgetStatistic, fetchCurriculumStatistic } from "../../api/statisticApi";

const statisticSlice = createSlice({
  name: "statistic",
  initialState: {
    areaStatistic: [],
    areaStatisticLoading: false,
    budgetStatistic: [],
    budgetStatisticLoading: false,
    curriculumStatistic: [],
    curriculumStatisticLoading: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAreaStatistic.pending, (state) => {
        state.areaStatistic = [];
        state.areaStatisticLoading = true;
      })
      .addCase(fetchAreaStatistic.fulfilled, (state, { payload }) => {
        state.areaStatistic = payload;
        state.areaStatisticLoading = false;
      })
      .addCase(fetchAreaStatistic.rejected, (state, { payload }) => {
        state.areaStatisticLoading = false;
      })
      .addCase(fetchBudgetStatistic.pending, (state) => {
        state.budgetStatistic = [];
        state.budgetStatisticLoading = true;
      })
      .addCase(fetchBudgetStatistic.fulfilled, (state, { payload }) => {
        state.budgetStatistic = payload;
        state.budgetStatisticLoading = false;
      })
      .addCase(fetchBudgetStatistic.rejected, (state, { payload }) => {
        state.budgetStatisticLoading = false;
      })
      .addCase(fetchCurriculumStatistic.pending, (state) => {
        state.curriculumStatistic = [];
        state.curriculumStatisticLoading = true;
      })
      .addCase(fetchCurriculumStatistic.fulfilled, (state, { payload }) => {
        state.curriculumStatistic = payload;
        state.curriculumStatisticLoading = false;
      })
      .addCase(fetchCurriculumStatistic.rejected, (state, { payload }) => {
        state.curriculumStatisticLoading = false;
      });
  },
});

export default statisticSlice.reducer;
