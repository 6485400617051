import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "../utils/axiosApi";

export const fetchAreaStatistic = createAsyncThunk("get/AreaStatistic", async () => {
  try {
    const response = await axiosApi.get("/statistic/area");
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetchBudgetStatistic = createAsyncThunk("get/BudgetStatistic", async () => {
  try {
    const response = await axiosApi.get("/statistic/budget");
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetchCurriculumStatistic = createAsyncThunk("get/CurriculumStatistic", async () => {
  try {
    const response = await axiosApi.get("/statistic/curriculum");
    return response.data;
  } catch (error) {
    console.log(error);
  }
});
