import { notification } from "antd";

export const showNotification = (type, text, width, title, duration = 3, placement = "topRight") => {
  notification[type]({
    message: title,
    description: text,
    className: ` notification-border ${type} ${width}`,
    duration,
    placement,
  });
};
