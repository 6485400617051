import { createSlice } from "@reduxjs/toolkit";
import { deleteSchool, getSchoolByID, getSchools } from "../../api/schoolApi";

const schoolSlice = createSlice({
  name: "school",
  initialState: {
    schools: null,
    school: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getSchools.pending]: (state) => {
      state.loading = true;
    },
    [getSchools.fulfilled]: (state, { payload }) => {
      state.schools = payload;
      state.loading = false;
    },
    [getSchools.rejected]: (state) => {
      state.loading = false;
    },
    [deleteSchool.pending]: (state) => {
      state.loading = true;
    },
    [deleteSchool.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getSchools.rejected]: (state) => {
      state.loading = false;
    },
    [getSchoolByID.pending]: (state) => {
      state.school = null;
      state.loading = true;
    },
    [getSchoolByID.fulfilled]: (state, { payload }) => {
      state.school = payload;
      state.loading = false;
    },
    [getSchoolByID.rejected]: (state) => {
      state.school = null;
      state.loading = false;
    },
  },
});

export default schoolSlice.reducer;

export const selectSchools = (state) => state.school;
