import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Typography } from "antd";
import { loginUser } from "../../api/authApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.scss";
const { Text } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLoginUser = async (data, e) => {
    e.preventDefault();
    await dispatch(loginUser(data)).then(() => navigate("/"));
  };

  return (
    <div className="container login">
      <div className="login__form">
        <form onSubmit={handleSubmit(handleLoginUser)}>
          <Text className="login__form__title">Login</Text>
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email is required",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Valid email please",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChange={onChange}
                status={errors.email ? "error" : ""}
                className="default-input"
                placeholder="Email"
                size="large"
                style={{ minHeight: "50px", maxHeight: "50px" }}
              />
            )}
          />

          {errors.email && (
            <Text type="danger" className="error__validation">
              {errors.email.message}
            </Text>
          )}

          <Controller
            control={control}
            name="password"
            rules={{
              required: "Password is required",
              minLength: {
                value: 1,
                message: `Min length ${1}`,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChange={onChange}
                status={errors.email ? "error" : ""}
                className="default-input"
                placeholder="Password"
                type="password"
                size="large"
                style={{ minHeight: "50px", maxHeight: "50px" }}
              />
            )}
          />

          {errors.password && (
            <Text type="danger" className="error__validation">
              {errors.password?.message}
            </Text>
          )}
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ minHeight: "50px", maxHeight: "50px" }}
          >
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
