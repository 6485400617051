import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../../api/authApi";
import { loadFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "../../utils/localStorage";

const token = loadFromLocalStorage("accessToken", false);
const user = loadFromLocalStorage("email", false);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user,
    token,
  },
  reducers: {
    initUserData: (state) => {
      const accessToken = loadFromLocalStorage("accessToken", false);
      if (accessToken) {
        state.token = accessToken;
      }
    },
    logOutUser: (state) => {
      state.token = null;
      state.user = null;
      removeFromLocalStorage("accessToken");
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {},
    [loginUser.fulfilled]: (state, { payload }) => {
      state.user = payload?.email;
      state.token = payload?.token;
      saveToLocalStorage("accessToken", payload?.token, false);
      saveToLocalStorage("email", payload?.email, false);
    },
    [loginUser.rejected]: (state) => {},
  },
});

export const { initUserData, logOutUser } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
